import React from "react";
import "./Category.css";
import Timing from "./Timing";
import { RaceCompetitor } from "@/types/LeaderboardTypes";

interface CategoryProps {
  header: string;
  data: RaceCompetitor[];
}

const Category: React.FC<CategoryProps> = ({ header, data }) => {
  var result = [];
  if (data !== undefined) {
    for (var i in data) result.push(data[i]);
    localStorage.setItem("CatData", JSON.stringify(result));
  }

  if (result.length > 0) {
    result.sort(function compare(a, b) {
      let timeA = a.finishTime;
      let timeB = b.finishTime;
      return timeA - timeB;
    });
  }

  //need something here to remove all but first 10 results

  return (
    <>
      <div style={{ fontSize: "1vw", paddingBottom: "0.3vw" }}>{header}</div>
      <div className="timingsContainer">
        {result?.slice(0, 10).map((result, resultIndex) => (
          <Timing
            name={result.name}
            time={result.finishTime.toFixed(1)}
            position={resultIndex + 1}
            key={resultIndex}
          />
        ))}
      </div>
    </>
  );
};

export default Category;
