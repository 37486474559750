import React from "react";
import Category from "./Category";
import "./ResultsBoat.css";
import { RaceCompetitor } from "@/types/LeaderboardTypes";

interface ResultsBoatProps {
  data: RaceCompetitor[] | undefined;
  name: string;
  distance: number;
}

const ResultsBoat: React.FC<ResultsBoatProps> = ({ data, name, distance }) => {
  const filterByCategory = (ageBracket: string, gender: string) => {
    return (
      data?.filter(
        (competitor) =>
          competitor.ageBracket === ageBracket && competitor.gender === gender
      ) || []
    );
  };

  const u19Female = filterByCategory("U19", "Female");
  const openFemale = filterByCategory("Open", "Female");
  const u19Male = filterByCategory("U19", "Male");
  const openMale = filterByCategory("Open", "Male");

  return (
    <div className="categoryBox">
      <div className="resultsBoatHeader">
        <div style={{ fontSize: "2vw" }}>{name}</div>
        {/* <div style={{ fontSize: "1vw" }}>{distance} metres</div> */}
      </div>

      <div className="categoryContainer">
        <div className="headerAndResults">
          <Category header="U19 Female" data={u19Female} />
        </div>
        <div className="headerAndResults">
          <Category header="Open Female" data={openFemale} />
        </div>
        <div className="headerAndResults">
          <Category header="U19 Male" data={u19Male} />
        </div>
        <div className="headerAndResults">
          <Category header="Open Male" data={openMale} />
        </div>
      </div>
    </div>
  );
};

export default ResultsBoat;
