import React from "react";
import "./Timing.css";

interface TimingProps {
  name: string;
  time: number | string;
  position: number;
}

const Timing: React.FC<TimingProps> = ({ name, time, position }) => {
  var badgeStyle = {};
  if (position === 1) {
    badgeStyle = {
      background:
        "linear-gradient(149deg, rgba(241, 223, 165, 1) 0%, rgba(252, 204, 62, 1) 35%, rgba(136, 106, 0, 1) 100%)",
      color: "white",
    };
  } else if (position === 2) {
    badgeStyle = {
      background: "linear-gradient(149deg, #C0C0C0 0%, #808080 100%)",
      color: "white",
    };
  } else if (position === 3) {
    badgeStyle = {
      background: "linear-gradient(149deg, #DAA520 0%, #8B4513 100%)",
      color: "white",
    };
  } else {
    badgeStyle = {
      backgroundColor: "white",
      color: "darkslategrey",
    };
  }
  return (
    <div className="nameTimeBox">
      <div className="athletesResultsBox" style={{ ...badgeStyle }}>
        <div className="timePosition">
          <div className="athletesResultsBoxNumber">{position}</div>
          <div className="time" style={{ fontSize: "1vw" }}>
            {time}s
          </div>
        </div>
        <div className="athleteName">
          <p>{name}</p>
        </div>
      </div>
    </div>
  );
};

export default Timing;
