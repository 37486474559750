import React from "react";

const Test = () => {
  return (
    <div className="testContainer">
      <div className="windowInfo">Left Wall</div>
      <div className="windowsContainer">
        <div className="box" style={{ opacity: 0 }}>
          Box 1
        </div>
        <div className="box" style={{ opacity: 0.25 }}>
          Opacity 0.25
        </div>
        <div className="box" style={{ opacity: 0.5 }}>
          Opacity 0.5
        </div>
        <div className="box" style={{ opacity: 0.75 }}>
          Opacity 0.75
        </div>
        <div className="box" style={{ opacity: 1 }}>
          Opacity 1
        </div>{" "}
      </div>
      <div className="windowInfo">Right Wall</div>
    </div>
  );
};

export default Test;
