import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Results from "./pages/Results";
import Test from "./pages/Test";
import Leaderboard from "./components/Leaderboard";
import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  render() {
    return (
      <div className="body">
        <Router>
          <Routes>
            <Route path="/" element={<Results />} />
            <Route path="/test" element={<Test />} />
            <Route path="/*" element={<Leaderboard />} />{" "}
            {/* Catch-all route */}
          </Routes>
        </Router>
      </div>
    );
  }
}
export default App;
