// src/components/StatsBox.tsx
import React from "react";
import { RaceCompetitor } from "../types/LeaderboardTypes";

import styles from "./StatsBox.module.css";

interface StatsBoxProps {
  data: RaceCompetitor[];
}

const StatsBox: React.FC<StatsBoxProps> = ({ data }) => {
  // Calculate total competitors by summing up the product of each competitor's boat size
  const totalCompetitors = data.reduce(
    (acc, competitor) => acc + competitor.boatSize,
    0
  );

  const totalDistance = data.reduce(
    (acc, competitor) => acc + competitor.distance,
    0
  );

  const finishedRaces = data.filter(
    (competitor) => competitor.finished && competitor.finishTime > 0
  );

  const fastestTime =
    finishedRaces.length > 0
      ? Math.min(...finishedRaces.map((competitor) => competitor.finishTime))
      : null;

  const averageTime =
    finishedRaces.length > 0
      ? finishedRaces.reduce(
          (acc, competitor) => acc + competitor.finishTime,
          0
        ) / finishedRaces.length
      : null;

  return (
    <div className="categoryBox" style={{ backgroundColor: "white" }}>
      <div className="resultsBoatHeader" style={{ color: "black" }}>
        <div style={{ fontSize: "2vw" }}>Statistics</div>
      </div>
      <div className="categoryContainer">
        <Stat heading="Total Competitors" value={totalCompetitors} />
        <Stat
          heading="Total Distance Raced"
          value={totalDistance}
          unit="meters"
        />
        {fastestTime !== null ? (
          <Stat
            heading="Fastest Time"
            value={fastestTime.toFixed(2)}
            unit="seconds"
          />
        ) : (
          <Stat heading="Fastest Time" value="N/A" />
        )}
        {averageTime !== null ? (
          <Stat
            heading="Average Time"
            value={averageTime.toFixed(2)}
            unit="seconds"
          />
        ) : (
          <Stat heading="Average Time" value="N/A" />
        )}
      </div>
    </div>
  );
};

export default StatsBox;

interface StatProps {
  heading: string;
  unit?: string;
  value: number | string;
}

const Stat: React.FC<StatProps> = ({ heading, unit, value }) => {
  return (
    <div className={styles.statsMetric}>
      <div></div>
      <div className={styles.statsMetricHeading}>{heading}</div>
      <div className={styles.statsMetricValueAndUnit}>
        <div />
        <div className={styles.statsMetricValue}>{value}</div>
        {unit && <div className={styles.statsMetricUnit}>{unit}</div>}
      </div>
    </div>
  );
};
