import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import ResultsBoat from "./ResultsBoat";
import { RaceCompetitor } from "../types/LeaderboardTypes";
import StatsBox from "./StatsBox";

import "./Leaderboard.css";
import logo from "../assets/rowcave_logo_white.svg";
import olympics from "../assets/olympics.png";
import worldrowing from "../assets/World_Rowing_Federation_logo_white.svg";
import TeamNL from "../assets/teamnl.svg";
import TeamNL2 from "../assets/teamnl-2.svg";
import moveLab from "../assets/logo-movelab.svg";
import rp3logo from "../assets/rp3-logo.png";

const firebaseConfig = {
  apiKey: "AIzaSyCdUJ1nMEBLxn1xJ5u6hsJv0dMK4sk2Nms",
  authDomain: "rowcave-leaderboard.firebaseapp.com",
  databaseURL:
    "https://rowcave-leaderboard-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "rowcave-leaderboard",
  storageBucket: "rowcave-leaderboard.appspot.com",
  messagingSenderId: "332419237985",
  appId: "1:332419237985:web:a51ed155520450b7308220",
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

const Leaderboard: React.FC = () => {
  const [data, setData] = useState<RaceCompetitor[]>([]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const pathname = url.pathname.substring(1); // Remove leading slash
    const dbRefPath = pathname || "testLeaderboard"; // Provide a default path if empty

    const workoutsRef = ref(db, dbRefPath);
    onValue(workoutsRef, (snapshot) => {
      const newData: RaceCompetitor[] = [];
      snapshot.forEach((childSnapshot) => {
        const value = childSnapshot.val() as RaceCompetitor;
        newData.push(value);
      });
      setData(newData);
    });
  }, []);

  const filterDataByBoatSize = (boatSize: number): RaceCompetitor[] => {
    return data.filter((competitor) => competitor.boatSize === boatSize);
  };

  return (
    <>
      <div className="overall">
        <div className="headerBox">
          <div className="sponsors">
            <img alt="" style={{ height: "2vw" }} src={TeamNL} />
            <img alt="" style={{ height: "1.8vw" }} src={rp3logo} />
            <img alt="" style={{ height: "3vw" }} src={logo} />
            <img alt="" style={{ height: "2vw" }} src={moveLab} />
            <img alt="" style={{ height: "2vw" }} src={TeamNL} />
          </div>
        </div>
        <div className="athletesResults">
          <ResultsBoat
            name="Single"
            distance={250}
            data={filterDataByBoatSize(1)}
          />
          <ResultsBoat
            name="Double"
            distance={250}
            data={filterDataByBoatSize(2)}
          />
          <ResultsBoat
            name="Quad"
            distance={250}
            data={filterDataByBoatSize(4)}
          />
          <StatsBox data={data} />
        </div>
      </div>
      <div
        className="background"
        style={{
          height: "100%",
          width: "100vw",
          objectFit: "cover",
        }}
      />
    </>
  );
};

export default Leaderboard;
